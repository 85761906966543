export const configData = {
    APP_NAME: "Filmfest",
    BASE_URL: "https://filmfest.net",
    API_URL: "https://api.filmfest.net",
    LOGIN: 'LOGIN',
    SIGN_UP: 'SIGN_UP',
    UPDATE_USER: 'UPDATE_USER',
    UPDATE_PROFILE_PICTURE: 'UPDATE_PROFILE_PICTURE',
    FORM_SUBMITION_STATUS: 'FORM_SUBMITION_STATUS',
    BENEFITS_OF_SIGNUP: "Please sign up.",
    SEEK_INTERVAL: 15, //sec
    FACEBOOK_APP_ID: '2691199187706050' //e7883ea9635c1bab6050b1d5045581e6
}

export const Headers = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*',
    },
};

// export default configData;