import logo from '../assets/images/logo.png';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link, NavLink } from "react-router-dom";
import { loginUser, removeMessage } from '../redux/UserSlice';
import { useAppSelector, useAppDispatch } from '../redux/hooks';
import Image from 'react-bootstrap/Image';
import Dropdown from 'react-bootstrap/Dropdown';
import { userLogout } from '../redux/UserSlice';
import defaultProfile from '../assets/images/default-profile.png'
import { FaSearch, FaUser } from 'react-icons/fa';
import useServerInfo from '../hooks/ServerInfo';
import { imageOnErrorHandler } from '../Utils/Utils';
import { useEffect, useState } from 'react';
import axios from 'axios';

import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

const Header = () => {
    const { loading, error, isLoggedIn, user } = useAppSelector(state => state.user) || [];
    useServerInfo();
    const dispatch = useAppDispatch();
    const [showLogoutMessage, setShowLogoutMessage] = useState(false);
    const [expanded, setExpanded] = useState(false);

    useEffect(() => {
        if (showLogoutMessage) {
            setTimeout(() => {
                setShowLogoutMessage(false);
            }, 1000);
        }
    }, [showLogoutMessage])

    /* Start code for add useEffect for Google Login */
    // const location = useLocation();

    // useEffect(() => {
    //         const parsed = queryString.parse(location.search);
    //         let key = parsed.key;

    //         if (key == '2a10mS9y2TdzjEx70OTrB4QY4ejs9pbT4zEtIY8wsZ45X25leDa4Ysq')
    //         {   
    //             axios.get(`https://api.filmfest.net/success/glogin`).then(res=>{
    //                 const gId=res.data.sub;

    //                 axios.get(`https://api.filmfest.net/getUserInfo/${gId}`).then(res=>{

    //                     const userCredentials={
    //                             "email":res.data.email, 
    //                             "password":gId,
    //                         }

    //                     dispatch<any>(loginUser(userCredentials))
    //                     .unwrap()
    //                     .then((result: any) => {
    //                             // console.log(result);
    //                     })

    //                     .catch((rejectedValueOrSerializedError: any) => {
    //                         // console.log(rejectedValueOrSerializedError);
    //                     })
    //                 }); 
    //             });
    //         }
    //  }, []);    
    /* End code for add useEffect for Google Login */

    const logout = (e: any) => {
        const payload: any = {
            loading: false,
            user: [],
            error: "",
            isLoggedIn: false
        }
        const isLogout: any = dispatch(userLogout());
        isLogout && setShowLogoutMessage(true);
        window.open('https://filmfest.net', '_self');
    }

    return (
        <>
            <Navbar expanded={expanded} expand="lg" sticky="top">
                <Container fluid className="custom-screen-padding">
                    <Link to="/" className="navbar-brand"><img src={logo} alt="Filmfest" width="160" /></Link>
                    <Link to="https://submit.filmfest.net/submit-film/" className='m-0 d-none filmBtn' target='_blank'><Button className="btn default-btn mx-2">Submit Film</Button></Link>
                    <Navbar.Toggle onClick={() => setExpanded(!expanded)} aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="navbarScroll">
                        <Nav
                            className="screen-nav centerNav me-auto my-2 my-lg-0"
                            style={{ maxHeight: '100px' }}
                        >

                            {/*<NavLink onClick={() => setExpanded(false)} to="/home">Home</NavLink >*/}
                            <NavLink onClick={() => setExpanded(false)} to="/">Festival</NavLink >
                            {/* <NavLink onClick={() => setExpanded(false)} to="/festival">Festival</NavLink > */}
                            <NavLink onClick={() => setExpanded(false)} to="/genres">Genres</NavLink >
                            <NavLink onClick={() => setExpanded(false)} to="https://submit.filmfest.net/" target='_blank'>About</NavLink >
                        </Nav>
                        <Nav
                            className="my-2 my-lg-0 pt-1"
                            style={{ maxHeight: '100px' }}
                        >
                            <Link onClick={() => setExpanded(false)} className="nav-search-icon mx-4" to="/search" title="Search"><FaSearch size="25" /></Link>
                            <Link onClick={() => setExpanded(false)} to="https://submit.filmfest.net/submit-film/" className='m-0' target='_blank'><Button className="deskfilmbtn btn default-btn mx-2">Submit Film</Button></Link>
                            {
                                isLoggedIn
                                    ? <Dropdown>
                                        <Dropdown.Toggle id="dropdown-basic"><Image src={user.image} onError={imageOnErrorHandler} roundedCircle style={{ width: "2rem", height: "2rem", margin: "0 4px" }} />{user && user['name']}</Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Link onClick={() => setExpanded(false)} to="/account">Account</Link>
                                            <Link onClick={() => setExpanded(false)} to="/favorite">Favorites</Link>
                                            <Dropdown.Item eventKey="1" onClick={logout} className='m-0 logout-btn'>Logout</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    : <Link onClick={() => setExpanded(false)} to="/login" className='m-0'><Button className="navbar-button ">Login</Button></Link>
                            }
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            {
                showLogoutMessage && <Container className='logout-message'><span>Logout successfully...</span></Container>
            }

        </>
    );
};

export default Header;