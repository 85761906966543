import React, { useEffect, useState } from 'react'
import videojs from 'video.js';
import { useParams, useNavigate } from "react-router-dom";
import { Spinner } from 'react-bootstrap';
import 'video.js/dist/video-js.css';
import { FaArrowLeft } from 'react-icons/fa';
import { Headers, configData } from '../config/Config';
import axios from "axios";
import { useAppSelector } from '../redux/hooks';
import { browserName } from 'react-device-detect';

interface IStream {
    actor: [],
    banner: string,
    director: [],
    duration: number,
    filmmaker: [],
    genre: [],
    long_description: string,
    producer: [],
    rating: string,
    short_description: string,
    stream_file: string,
    thumbnail: string,
    title: string,
    trailer: string,
    _id: string,
}

const detailUrl: string = configData.API_URL + "/item-detail";
const MainPlayer = () => {
    const { user, isLoggedIn } = useAppSelector(state => state.user) || [];
    const navigate = useNavigate();
    const { id } = useParams();
    const videoRef: any = React.useRef(null);
    const playerRef: any = React.useRef(null);
    const [source, setSource] = useState({ src: '', type: '' });

    const [stream, setStream] = useState<IStream>({
        actor: [],
        banner: '',
        director: [],
        duration: 0,
        filmmaker: [],
        genre: [],
        long_description: '',
        producer: [],
        rating: '',
        short_description: '',
        stream_file: '',
        thumbnail: '',
        title: '',
        trailer: '',
        _id: '',
    });

    const videoJsOptions = {
        muted: browserName === "Chrome" ? true : false,
        playsinline: true,
        preload: true,
        autoplay: true,
        controls: true,
        responsive: true,
        fluid: true,
        cache: true,
        controlBar: { skipButtons: { forward: 5, backward: 5 }, 'pictureInPictureToggle': false },
        sources: [{
            src: "",
            type: ""
        }]
    };

    const handlePlayerReady = (player: any) => {
        playerRef.current = player;

        // You can handle player events here, for example:
        player.on('canplay', () => {
            videojs.log('player is canplay');
        });
        player.on('loadedmetadata', () => {
            videojs.log('loadedmetadata');
            const customPlayerUnmute: HTMLElement = document.querySelector('.custom-player-input') as HTMLElement;
            if (browserName === "Chrome") {
                customPlayerUnmute.style.display = 'block';
            }
            setTimeout(() => {
                customPlayerUnmute.style.display = 'none';
            }, 3000);
        });

        player.on('waiting', () => {
            // videojs.log('player is waiting');
        });

        player.on('playing', function () {
            videojs.log('player is playing');
        });

        player.on('play', () => {
            videojs.log('play');
        });

        player.on('timeupdate', (e: object) => {
            // videojs.log('timeupdate');
            appendMovieTitle()
        });

        player.on('dispose', () => {
            // videojs.log('player will dispose');
        });

        player.on('error', (e: object) => {
            videojs.log('player error');
            const customPlayerUnmute: HTMLElement = document.querySelector('.custom-player-input') as HTMLElement;
            customPlayerUnmute.style.display = 'none';
        });
    };

    useEffect(() => {
         const BASE_URL = configData.API_URL;
         axios.get(`${BASE_URL}/get-mov/${id}`)
         .then(res=>{
         let count_mov = res.data.movieData[0].watch_mov;
              count_mov=count_mov+1
                console.log(count_mov);

           fetch(`${BASE_URL}/change-watchmov/${id}/${count_mov}`, {
           method: 'PUT',
            });
         });
    }, []);    

    useEffect(() => {
        const getDetailData = async () => {
            const param = {
                item_id: id,
                ...(isLoggedIn && { user_id: user['_id'] })
            }
            await axios.post(detailUrl, param)
                .then(res => {
                    setStream(res.data.detail)
                })
                .catch(error => {
                    // console.log(error);
                });
        }
        getDetailData();
    }, []);

    useEffect(() => {
        setSource({ src: stream.stream_file, type: stream.stream_file.indexOf('.m3u8') > -1 ? 'video/m3u8' : 'video/mp4' });

        // Make sure Video.js player is only initialized once
        if (!playerRef.current && stream.stream_file !== '') {
            // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode. 
            const videoElement = document.createElement("video-js");
            videoElement.classList.add('vjs-big-play-centered');
            // if (videoRef.current !== null) {
            //     videoRef.current.focus();
            // } else videoRef.current.appendChild(videoElement);
            videoRef.current.appendChild(videoElement);

            videoJsOptions.sources = [{ src: stream.stream_file, type: stream.stream_file.indexOf('.m3u8') > -1 ? 'video/m3u8' : 'video/mp4' }];

            const player = playerRef.current = videojs(videoElement, videoJsOptions, () => {
                videojs.log('player is ready');
                handlePlayerReady && handlePlayerReady(player);
            });

            player.play();

            // You could update an existing player in the `else` block here
            // on prop change, for example:
        } else if (playerRef.current && stream.stream_file) {
            const player: any = playerRef.current;
            player.autoplay(true);
            player.src([{ src: source.src, type: source.type }]);
            player.play();
        }
    }, [stream]);

    // Dispose the Video.js player when the functional component unmounts
    useEffect(() => {
        const player = playerRef.current;
        return () => {
            if (player && !player.isDisposed()) {
                player.dispose();
                playerRef.current = null;
            }
        };
    }, [playerRef]);

    const unmutePlayer = () => {
        const muteBtn: HTMLElement = document.querySelector('.vjs-mute-control') as HTMLElement;
        muteBtn.click();
        const customPlayerUnmute: HTMLElement = document.querySelector('.custom-player-input') as HTMLElement;
        customPlayerUnmute.style.display = 'none';
    }


    const appendMovieTitle = () => {
        if (!document.querySelector('#movieTitle')) {
            const div: any = document.querySelector('.video-js-player div');
            const x = document.createElement("DIV");
            x.setAttribute("id", "movieTitle");
            x.setAttribute("class", "player-movie-title");
            const t = document.createTextNode(stream.title);
            x?.appendChild(t);
            div?.appendChild(x);
        }

        const movieTitle: any = document.querySelector('#movieTitle');
        movieTitle.innerHTML = stream.title;

        const controlBar: any = document.querySelector('.vjs-control-bar');
        const backBtn: any = document.querySelector('#playerBackBtn');
        if (window.getComputedStyle(controlBar).opacity == '0') {
            backBtn.setAttribute('style', "opacity: 0;")
            movieTitle.setAttribute('style', "opacity: 0;");
        } else {
            backBtn.setAttribute('style', "opacity: 1;");
            movieTitle.setAttribute('style', "opacity: 1;");
        }
    }

    return false
        ? <div style={{ textAlign: "center", color: "#FFFFFF", width: '100%', height: '100vh', display: 'grid', placeItems: 'center' }}><Spinner></Spinner></div>
        : (
            <div className='player-container'>
                <div className='custom-player-input'>
                    <div className='custom-tooltip'>
                        <h6>Do you want to unmute?</h6>
                        <span className='unmute-btn' onClick={unmutePlayer}>OK</span>
                    </div>
                </div>
                <span onClick={() => navigate(-1)} id='playerBackBtn'><FaArrowLeft className='player-back-arrow' /></span>
                <div className='video-js-player' vjs-fluid="true" data-vjs-player>
                    <div ref={videoRef} />
                </div>
            </div>
        );

}

export default MainPlayer;

