import React, { useEffect, useState } from 'react'
import axios from 'axios';

interface IServer {
    IPv4: string;
    city: string;
    country_code: string;
    country_name: string;
    latitude: number;
    longitude: number;
    postal: string;
    state: string;
}

const useServerInfo = () => {
    const [serverInfo, setServerInfo] = useState<IServer>({
        IPv4: '',
        city: '',
        country_code: '',
        country_name: '',
        latitude: 0,
        longitude: 0,
        postal: '',
        state: '',
    });

    useEffect(() => {
        const getServerInfo = async () => {
            await axios.get<IServer[], any>('https://geolocation-db.com/json/')
                .then(response => {
                    setServerInfo(response.data);
                })
                .catch(error => {
                    // console.log(error)
                });
        }

        getServerInfo();

    }, []);
}

export default useServerInfo