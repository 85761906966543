import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import { GoogleOAuthProvider } from '@react-oauth/google';

import Layout from './layouts/Layout';
import { handelRightClick } from './Utils/Utils';



function App() {
  document.addEventListener('contextmenu', handelRightClick);
  return (
    <>
      <GoogleOAuthProvider clientId="246187000807-fveipl508n74aman63o3elvqvn8e62us.apps.googleusercontent.com">
        <Layout />
      </GoogleOAuthProvider></>

  );
}

export default App;
