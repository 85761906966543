import { useEffect, useState } from 'react'
import { configData } from '../config/Config'


const _extends = Object.assign || function (target: any) {
    for (let i = 1; i < arguments.length; i++) {
        const source = arguments[i]
        for (const key in source) {
            if (Object.prototype.hasOwnProperty.call(source, key)) {
                target[key] = source[key]
            }
        }
    }
    return target
}

const useFacebook = function () {
    const appId = configData.FACEBOOK_APP_ID;
    const [isReady, setIsReady] = useState(false);
    useEffect(() => {
        (window as any).fbAsyncInit = function () {
            (window as any).FB.init({
                appId,
                autoLogAppEvents: true,
                xfbml: true,
                version: 'v8.0'
            })
            setIsReady(true)
        };

        (function (d, s, id) {
            if (d.getElementById(id)) {
                setIsReady(true)
                return
            }

            const fjs: any = d.getElementsByTagName(s)[0]
            const js: any = d.createElement(s)
            js.id = id
            js.src = `https://connect.facebook.net/en-US/sdk.js`
            js.async = true
            js.defer = true
            fjs.parentNode.insertBefore(js, fjs)
        })(document, 'script', 'facebook-jssdk')
    }, [])

    const facebook = {
        login: async (): Promise<any> => {
            const { authResponse, status } = await new Promise<any>(resolve => (window as any).FB.login(resolve, { scope: 'public_profile,email' }))
            if (!authResponse) return { status }

            return new Promise<any>(resolve => (window as any).FB.api('/me', { locale: 'en_US', fields: 'name,email,picture' }, (me: any) => {
                // console.log(me, authResponse);
                _extends(me, authResponse)
                resolve(me)
            }));

        }
    }

    return [facebook, isReady]
}

export default useFacebook