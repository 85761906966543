import React, { useCallback, useState } from 'react';
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';

import { Button, Col } from 'react-bootstrap';
// import { GoogleOAuthProvider,GoogleLogin } from '@react-oauth/google';
import google from '../assets/images/google.png';
import facebookIcon from '../assets/images/facebook.png';
import { FacebookLoginButton, GoogleLoginButton } from "react-social-login-buttons";
import { LoginSocialFacebook, IResolveParams } from 'reactjs-social-login';
import axios from 'axios';
import { googleUSer, loginUser, removeMessage } from '../redux/UserSlice';
import { useEffect } from 'react';
import { configData } from '../config/Config';
import { useAppSelector, useAppDispatch } from '../redux/hooks';
import useFacebook from '../hooks/Facebook';
import App from '../App';
const REDIRECT_URI = 'https://filmfest.net'


export const SocialMediaButton = () => {
    const navigate = useNavigate();
    const [provider, setProvider] = useState('');

    const [profile, setProfile] = useState();
    const [facebook, isFacebookReady] = useFacebook();
    const dispatch = useAppDispatch();
    const onLoginStart = useCallback(() => {
        console.log('login start');
    }, []);

    const onLogoutSuccess = useCallback(() => {
        setProfile(null);
        setProvider('');
        console.log('logout success');
    }, []);

    const onLogout = useCallback(() => { }, []);

    // const handleGoogleLogin = () => {
    //     // window.open('https://api.filmfest.net/google/auth','_self');

    //     axios.get(`https://api.filmfest.net/success/glogin`).then(res => {
    //         const gId = res.data.sub;

    //         axios.get(`https://api.filmfest.net/getUserInfo/${gId}`).then(res => {
    //             console.log('A=>' + gId + '===' + res.data.email);
    // const userCredentials = {
    //     "email": res.data.email,
    //     "password": gId,
    // }

    // dispatch < any > (loginUser(userCredentials))
    //     .unwrap()
    //     .then((result) => {
    //         // console.log(result);
    //     })

    //                 .catch((rejectedValueOrSerializedError: any) => {
    //                     // console.log(rejectedValueOrSerializedError);
    //                 })
    //         });

    //     });




    // }

    const googlelogin = useGoogleLogin({
        onSuccess: tokenResponse => {
            console.log(tokenResponse, "I am here")

            axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${tokenResponse.access_token}`)
                .then(response => {
                    const userData = response.data;
                    console.log('User Data:', userData);

                    let obj = { name: userData.given_name, email: userData.email, google_id: userData.id, image: userData.image, password: userData.id }
                    axios.post(`https://api.filmfest.net/google-login`, obj)
                        .then(response => {
                            console.log('User Data:', response.data.user);
                            const userCredentials = {
                                "createdAt": response.data.user.createdAt,
                                "email": response.data.user.email,
                                "name": response.data.user.name,
                                "status": response.data.user.status,
                                "image": response.data.user.image,
                                "_id": response.data.user._id,
                                "isGoogle": true
                            }




                            dispatch(googleUSer(userCredentials))
                                .unwrap()
                                .then((result) => {
                                    // console.log(result);
                                })
                        })
                        .catch(error => {
                            console.error('Error fetching user data:', error);
                        });
                })
                .catch(error => {
                    console.error('Error fetching user data:', error);
                });
            // console.log(decoded, "TESTT THE DATA")

        }
    });
    const handleFacebookLogin = async () => {
        window.open('https://api.filmfest.net/facebook/auth', '_self');
    }

    return (
        <Col>
            {/* <LoginSocialFacebook
                appId={configData.FACEBOOK_APP_ID || ''}
                fieldsProfile={
                    'id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender'
                }
                onLoginStart={onLoginStart}
                onLogoutSuccess={onLogoutSuccess}
                redirect_uri={REDIRECT_URI}
                onResolve={({ provider, data }: IResolveParams) => {
                    console.log(provider);
                    console.log(data);
                    setProvider(provider);
                    setProfile(data);
                }}
                onReject={err => {
                    console.log(err);
                }}
            >
                <FacebookLoginButton onClick={() => console.log("Facebook")} />
            </LoginSocialFacebook> */}
            {/* <button type='button' onClick={handleFacebookLogin} disabled={!isFacebookReady}>Login with Facebook</button> */}
            {/* <GoogleLoginButton onClick={() => alert("Google")} /> */}

            {/* 
            <GoogleLogin
                onSuccess={credentialResponse => {
                    console.log(credentialResponse);


                    console.log(decoded, "TESTT THE DATA")
                    let obj = { name: decoded.name, email: decoded.email, google_id: decoded.sub, image: decoded.image, password: decoded.jti }
                    axios.post(`http://localhost:5000/google-login`, obj)
                        .then(response => {
                            console.log('User Data:', response.data.user);
                            const userCredentials = {
                                "createdAt": response.data.user.createdAt,
                                "email": response.data.user.email,
                                "name": response.data.user.name,
                                "status": response.data.user.status,
                                "image": response.data.user.image,
                                "_id": response.data.user._id,
                                "isGoogle": true
                            }




                            dispatch(googleUSer(userCredentials))
                                .unwrap()
                                .then((result) => {
                                    // console.log(result);
                                })
                        })
                        .catch(error => {
                            console.error('Error fetching user data:', error);
                        });
                }}
                onError={() => {
                    console.log('Login Failed');
                }}
            />; */}
            <Button className='whiteBtn facebook-icon my-2 p-0' size="lg" onClick={googlelogin}><img src={google} alt="google" /> Continue with Google</Button>
            {/* <Button className='whiteBtn google-icon p-0' size="lg" onClick={handleFacebookLogin} disabled={!isFacebookReady}><img src={facebookIcon} alt="facebook" /> Continue with Facebook</Button> */}
        </Col>
    )
}
